import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = (props) =>
    makeStyles((theme) => ({
        blinkingCursor: {
            position: 'relative',
            font: 'inherit',
            color: 'inherit',
            lineHeight: 'inherit',
            left: '3px',
            top: '0',
            animationName: 'blink',
            animationDuration: '0.8s',
            animationIterationCount: 'infinite',
            opacity: '1',
        },
    }))

export interface CursorProps {
    cursorStyle?: string
}

export const Cursor = (
    { cursorStyle = '|' }: CursorProps,
    props
): JSX.Element => {
    const classes = useStyles(props)()
    return <span className={classes.blinkingCursor}>{cursorStyle}</span>
}
