import React, { useEffect, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import options from '../../templates/richTextRenderers'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Typewriter } from '../../components/TypeWriter/Typewriter.tsx'

import ButtonSelector from '../../components/ButtonSelector'

import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'

import Masonry from 'react-masonry-css'
import lottie from 'lottie-web'

const useStyles = (props) =>
    makeStyles((theme) => ({
        heading: {
            fontFamily: 'IBM Plex Sans',
            fontWeight: '700',
            fontSize: '1.125rem',
            lineHeight: '1.463rem',
            color: theme.palette.text.green,
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.875rem',
                lineHeight: '1.125rem',
            },
        },
        title: {
            width: '100%',
            color: '#130D1F',
            fontFamily: 'IBM Plex Mono',
            fontWeight: '700',
            fontSize: '3.375rem',
            lineHeight: '4.25rem',
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.25rem',
                lineHeight: '1.5rem',
            },
        },
        typewriter: {
            color: '#F45F00',
            fontFamily: 'IBM Plex Mono',
            fontWeight: '700',
            fontSize: '3.375rem',
            lineHeight: '4.25rem',
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.25rem',
                lineHeight: '1.5rem',
            },
        },
        text: {
            fontFamily: 'IBM Plex Sans',
            fontWeight: '400',
            fontSize: '1.5rem',
            lineHeight: '2.375rem',
            color: theme.palette.text.gray,
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.875rem',
                lineHeight: '1.125rem',
            },
        },
        buttonPrimary: {
            width: '100%',
            height: '4.5rem',
            borderRadius: '0.938rem',
            color: theme.palette.text.white,
            fontSize: '1.25rem',
            lineHeight: '22px',
            textAlign: 'center',
            margin: '50px 0px',
            background:
                'linear-gradient(138.25deg, #FFAF75 7%, #F45F00 95.28%)',
            '&:hover': {
                background:
                    'linear-gradient(138.25deg, rgba(255, 175, 117, 0.5) 7%, rgba(244, 95, 0, 0.5) 95.28%)',
            },
            [theme.breakpoints.down('sm')]: {
                width: '85%',
                height: '2.5rem',
                fontSize: '0.875rem',
                lineHeight: '1.125rem',
                margin: '24px 0px',
            },
        },
        buttonSecondary: {
            width: '100%',
            height: '4.5rem',
            borderRadius: '0.938rem',
            color: theme.palette.text.white,
            fontSize: '1.25rem',
            lineHeight: '22px',
            textAlign: 'center',
            margin: '50px 0px',
            background:
                'linear-gradient(152.51deg, #14FFD7 -64.25%, #75CCBD 105.81%)',
            '&:hover': {
                background:
                    'linear-gradient(152.51deg, rgba(20, 255, 215, 0.5) -64.25%, rgba(117, 204, 189, 0.5))',
            },
            [theme.breakpoints.down('sm')]: {
                width: '85%',
                height: '2.5rem',
                fontSize: '0.875rem',
                lineHeight: '1.125rem',
                margin: '24px 0px',
            },
        },
    }))

const HeroImages = (props) => {
    const data = props.data[props.componentId]

    const classes = useStyles({ ...props, data })()

    const breakpointColumnsObj = {
        default: 2,
        1100: 2,
        700: 2,
        500: 2,
    }

    const orientation = (input) => {
        return input === 'Text -> Image' ? 'row-reverse' : 'row'
    }

    const direction = orientation(data.orientation)

    const hasHeading = Boolean(data.heading)
    const hasTitle = Boolean(data.title)
    const hasText = Boolean(data.text)
    const hasIcons = Boolean(data.icons)
    const hasButtons = Boolean(data.buttons)
    const hasImages = Boolean(data.images)
    const hasTypewriter = Boolean(data.typewriter)
    const hasLottie = Boolean(
        data.animation && data.animation.file && data.animation.file.url
    )

    const icons = getImage(data.icons)

    const words = hasTypewriter && data.typewriter.split(', ')

    const container = useRef(null)

    const [animationLoaded, setAnimationLoaded] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = data.animation.file.url
                const response = await fetch(url)
                const json = await response.json()

                lottie.loadAnimation({
                    container: container.current,
                    renderer: 'svg',
                    loop: true,
                    autoplay: true,
                    animationData: json,
                })
                setAnimationLoaded(true)
            } catch (error) {
                console.log('error', error)
            }
        }
        if (hasLottie && !animationLoaded) {
            fetchData()
        }
    }, [hasLottie, animationLoaded])

    const numberOfImages = (input) => {
        if (input === 4) {
            return (
                <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                >
                    {hasImages &&
                        data.images.map((input) => {
                            const zimages = getImage(input)
                            return (
                                <div>
                                    <GatsbyImage
                                        image={zimages}
                                        alt={input.title}
                                    />
                                </div>
                            )
                        })}
                </Masonry>
            )
        }
        return (
            <Box>
                {hasImages &&
                    data.images.map((input) => {
                        const zimages = getImage(input)
                        return (
                            <div>
                                <GatsbyImage
                                    image={zimages}
                                    alt={input.title}
                                />
                            </div>
                        )
                    })}
            </Box>
        )
    }

    return (
        <Box pb={5}>
            <Container maxWidth="lg">
                <Grid container spacing={2} direction={direction}>
                    <Grid item xs={12} sm={5}>
                        {hasImages && numberOfImages(data.images.length)}
                        {hasLottie && (
                            <div className="container" ref={container}></div>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={7} style={{ marginTop: '20px' }}>
                        {hasHeading && (
                            <Typography className={classes.heading}>
                                {data.heading}
                            </Typography>
                        )}
                        {hasTitle && !hasTypewriter && (
                            <Typography className={classes.title}>
                                {data.title}
                            </Typography>
                        )}
                        {hasTypewriter && (
                            <Grid container>
                                <Typography className={classes.typewriter}>
                                    <Grid item>
                                        <span className={classes.title}>
                                            {data.title}&nbsp;
                                        </span>
                                    </Grid>
                                    <Grid item>
                                        <Typewriter
                                            words={words}
                                            loop
                                            cursor
                                            cursorStyle="_"
                                            typeSpeed={70}
                                            deleteSpeed={50}
                                            delaySpeed={1000}
                                        />
                                    </Grid>
                                </Typography>
                                <Typography className={classes.title}>
                                    {data.titlePart2}
                                </Typography>
                            </Grid>
                        )}

                        {hasText && (
                            <Typography className={classes.text}>
                                {renderRichText(data.text, options())}
                            </Typography>
                        )}
                        {hasButtons && (
                            <Grid container spacing={2} alignItems="flex-start">
                                {data.buttons.map((button) => {
                                    const buttonColor = (color) => {
                                        if (color === 'Orange') {
                                            return classes.buttonPrimary
                                        }
                                        return classes.buttonSecondary
                                    }
                                    const theButtonColor = buttonColor(
                                        button.buttonColor
                                    )
                                    const hasTagClass = button.tagClass
                                        ? button.tagClass
                                        : ''
                                    return (
                                        <Grid item xs={6} md={4}>
                                            <ButtonSelector
                                                className={`${theButtonColor} ${hasTagClass}`}
                                                data={button}
                                            >
                                                {button.label}
                                            </ButtonSelector>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        )}
                        {hasIcons && (
                            <Grid container item spacing={2}>
                                <Grid item xs={5} md={4}>
                                    <GatsbyImage
                                        image={icons}
                                        alt={data.icons.title}
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}
export default HeroImages
